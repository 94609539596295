import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';

import type * as ApiDataExportStrategyModule from 'o365.pwa.declaration.sw.strategies.api.data.export.strategy.d.ts';

import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { StrategyOptions } from 'o365.pwa.declaration.sw.workbox.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    class ApiDataExportStrategy extends self.workbox.strategies.Strategy implements ApiDataExportStrategyModule.ApiDataExportStrategy {
        constructor(options: StrategyOptions) {
            super(options);
        }

        _handle(request: Request, handler: typeof self.workbox.strategies.StrategyHandler) {
            return handler.fetch(request);
        }
    }

    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.strategies.api.data.export.strategy.d.ts')>({ ApiDataExportStrategy, self })
})();
